import React from 'react';
import './benefits.css';
import Feature from '../feature/Feature';

const featuresData = [
  {
    title: 'Centralised Package Management',
    text: 'Simplify your workflow by managing all your service packages conveniently in one location.',
  },
  {
    title: 'Personalised Content Discovery',
    text: 'Enjoy a tailored experience free from algorithmic interference, allowing you to view content based on your preferences.',
  },
  {
    title: 'Access to Support and Resources',
    text: 'Benefit from comprehensive support in client management, legal matters, and business development.',
  },
 
]

const Benefits = () => {
  return (
    <div className='imfonin__benefits section__padding' id='benefits'>
      <div className='imfonin__benefits-heading'>
        <h1 className='gradient__text'>Efficient Photo Organisation</h1>
        <p>Keep your image library tidy and accessible with Imfonin's intuitive album feature.</p>
      </div>

      <div className='imfonin__benefits-container'>
        <div className='imfonin__benefits-container_card'>
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Benefits