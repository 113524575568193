import React from 'react';
import { NavBar, Hero, CTA, Functions, Benefits, CaseStudy, Footer, Interlude } from './components';

import './App.css';

function App() {
  return (
    <div className='App'>
      <div className="background">
        <div className='gradient__bg'>
          <NavBar />
      <Hero />
        </div>
      </div>
      <Functions />
      <Interlude />
      <Benefits />
      <CTA />
      <CaseStudy />
      <Footer />
    </div>
  );
}

export default App;
