import React from 'react'
import './herocards.css'

const Herocards = ({ title, text, text1, text2, text3, text4, text5 , link}) => {
  return (
    <div className='imfonin__herocards-container'>
        <div className='imfonin__herocards-container__title'>
              <h1>{title}</h1>
              <div></div>
        </div>
        <div className='imfonin__herocards-container__text'>
              <ul>
              <li>{text}</li>
              <li>{text1}</li>
              <li>{text2}</li>
              <li>{text3}</li>
              <li>{text4}</li>
              <li>{text5}</li>
              </ul>
          </div>
          { link }
          
    </div>
  )
}

export default Herocards