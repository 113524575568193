import React from 'react';
import './casestudy.css';
import { happyMan } from '../../images';


const CaseStudy = () => {
  return (
    <div className='imfonin__case_study section__padding' id='casestudy'>
      <div className='imfonin__case_study-image'>
        <img src={happyMan} alt='happy man' />
      </div>
      <div className='imfonin__case_study-content'>
        <h1 className='gradient__text' >The possibilities are beyond imagination</h1>
        <h4>Maximise Earnings with Tip Collection</h4>
        <p>Utilise the tip button to accumulate contributions from appreciative clients, enhancing your overall income.</p>
        <h4>Streamlined Booking Experience</h4>
        <p>Receive bookings directly to your device, simplifying the process and reducing administrative burdens.</p>
        <h4>Stay Informed with Subscription Updates</h4>
        <p>Stay abreast of the latest developments in your community by subscribing to your favourite photographers.</p>
        <h4>Effortless Photographer Selection</h4>
        <p>Eliminate the hassle of finding the perfect photographer by utilising our user-friendly booking process.</p>

      </div>
    </div>
  )
}

export default CaseStudy