import React from 'react';
import './interlude.css';
import { happyGirl } from '../../images';


const Interlude = () =>{
  return (
    <div className='imfonin__interlude' >
      <div className='imfonin__interlude-image'>
        <img src={happyGirl} alt='happy girl' />
      </div>
    </div>
  )
}

export default Interlude