import React from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import { imfoninLogo, imfoninIcon } from '../../images';
import './navbar.css';


const Menu = () => (
  <>
   <p><a href="#home">Home</a></p>
        <p><a href="#whatisimfonin">What is Imfonin</a></p>
        <p><a href="#benefits">Benefits</a></p>
        <p><a href="#casestudy">Case Studies</a></p>
  </>
)
const NavBar = () =>  {

  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <div className='imfonin__navbar'>
      <div className='imfonin__navbar-links'>
        <div className='imfonin__navbar-links_logo'>
          <img src={imfoninIcon} alt="logo" />
        </div>
        <div className='imfonin__navbar-links_mobilelogo'>
          <img src={imfoninLogo} alt="logo" />
        </div>
        <div className='imfonin__navbar-links_container'>
          <Menu />
        </div>
        <div className='imfonin__navbar-sign'>
          <button type='button'>
            <a href='#signup'>Sign up</a>
          </button>
        </div>
        <div className='imfonin__navbar-menu'>
          {toggleMenu ? <RiCloseLine color='#fff' size={27} onClick={() => setToggleMenu(false)} /> : <RiMenu3Line color='#fff' size={27} onClick={() => setToggleMenu(true)} />} 
          {toggleMenu && (
            <div className='imfonin__navbar-menu_container scale-up-center'> 
              <div className='imfonin__navbar-menu_container-links'>
          <Menu />
              </div>
              <div className='imfonin__navbar-menu_container-links-sign'>

                <button type='button'>
                  <a href='#signup'>Sign up</a>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <a href="#" class="float">
<i class="arrow up"></i>
</a>
    </div>
    
  )
}

export default NavBar