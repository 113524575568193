import React, { useEffect } from 'react';
import './cta.css';

const CTA = () => {
  useEffect(() => {
    // Select all checkboxes with the class 'option-checkbox'
    const checkboxes = document.querySelectorAll('.option-checkbox');

    checkboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', handleCheckboxChange);
    });

    return () => {
      checkboxes.forEach((checkbox) => {
        checkbox.removeEventListener('change', handleCheckboxChange);
      });
    };
  }, []);

  const handleCheckboxChange = () => {
    const checkboxes = document.querySelectorAll('.option-checkbox');
    const checkedCount = document.querySelectorAll('.option-checkbox:checked').length;

    if (checkedCount >= 3) {
      checkboxes.forEach((checkbox) => {
        if (!checkbox.checked) {
          checkbox.disabled = true;
        }
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.disabled = false;
      });
    }
  };

  async function submit(e) {
    const formElement = document.querySelector('form');
    e.preventDefault();
    const formData = new FormData(formElement);
    
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbzQIVIwnB8j12FYYTaiiMQR2bqo3bIjgdnXhbqcVblSxBHegrD4vtkvEDr-9pgiaY1VHw/exec', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        alert('Thank you for joining our waitlist. You will be one of the first to know when we are live!' );
      } else {
        alert('Form submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred while submitting the form. Please try again.');
    }

    formElement.reset();
    handleCheckboxChange(); // Reset the checkbox state
  }

  return (
    <div className='imfonin__cta' id='signup'>
      <h1>User Sign up</h1>
      <p>Tell us a little about yourself so that we can bring you the best product. We will notify you first when we are ready to roll.</p>
      <div className='imfonin__cta-content_input'>
        <form onSubmit={(e) => submit(e)}>
          <div htmlFor="Type" style={{marginBottom: '16px', fontSize: '16px', color: '#FFF'}}>Are you a photographer? *</div>
          
          <select name='Type' id='Type' required>
            <option value=''>Please select..</option>
            <option value='Photographer'>Yes</option>
            <option value='Client'>No</option>
          </select>

          <input type="name" placeholder='Your Name *' name='Name' required />
          <input type="email" placeholder='Your Email Address *' name='Email' required />
          <input type="phone" placeholder='Your Phone Number' name='Phone' />
          <div className='imfonin__cta-content_input-select'>
            <p></p>
            <p style={{marginBottom: '8px', fontSize: '16px'}}>What are you looking for? </p>
            <div style={{marginBottom: '2rem', color: '#666', textAlign: 'center'}}>(Select 3 options)</div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type='checkbox' value='Manage my bookings' name='Option0' className='option-checkbox' />
              <label htmlFor="Option0">Manage my bookings</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Finding clients' name='Option1' className='option-checkbox' />
              <label htmlFor="Option1">Finding clients</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Receive tips and tokens' name='Option2' className='option-checkbox' />
              <label htmlFor="Option2">Receive tips and tokens</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Showcasing my work' name='Option3' className='option-checkbox' />
              <label htmlFor="Option3">Showcasing my work</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Managing and receiving payments' name='Option4' className='option-checkbox' />
              <label htmlFor="Option4">Managing and receiving payments</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Find Photographers' name='Option5' className='option-checkbox' />
              <label htmlFor="Option5">Find Photographers</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Compare Their Prices' name='Option6' className='option-checkbox' />
              <label htmlFor="Option6">Compare Their Prices</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='See Their Work' name='Option7' className='option-checkbox' />
              <label htmlFor="Option7">See Their Work</label>
            </div>
            <div className='imfonin__cta-content_input-select_option'>
              <input type="checkbox" value='Book Quickly' name='Option8' className='option-checkbox' />
              <label htmlFor="Option8">Book Quickly</label>
            </div>
            
          </div>
          <input type="text" placeholder='Other' name='Option9' />
          <p></p>
          <div htmlFor="Type" style={{marginBottom: '16px', fontSize: '16px', color: '#FFF'}}>How did you hear about us?</div>
          
          <select name='Option10' id='Source'>
            <option value=''>Please select..</option>
            <option value='X'>X</option>
            <option value='Tiktok'>Tiktok</option>
            <option value='Instagram'>Instagram</option>
            <option value='Facebook'>Facebook</option>
            <option value='LinkedIn'>LinkedIn</option>
            <option value='Search Engine'>Search Engine</option>
            <option value='A friend'>A friend</option>
            <option value='Other'>Other</option>
          </select>

          <p></p>
          <button type='submit'>Get Started</button>
        </form>
      </div>
    </div>
  );
};

export default CTA;
