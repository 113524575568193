import React from 'react';
import {Feature } from '../../components';
import './functions.css';


const Functions = () => {
  return (
    <div className='imfonin__functions section__padding section__margin' id='whatisimfonin'>
      <div className='imfonin__functions-feature'>
        <Feature title="Express Gratitude" text="Appreciate outstanding content creators by sending them tokens for their exceptional work." />
      </div>
      <div className='imfonin__functions-heading'>
        <h1 className='gradient__text'>Effortlessly Book Photographers</h1>
        <p>Seamlessly schedule and confirm bookings without unnecessary complications or delays</p>
      </div>
      <div className='imfonin__functions-container'>
        <Feature title="Portfolio Uploads " text="Present your best work to potential clients, attracting them with your talent and professionalism."/>
        <Feature title="Engage Your Audience" text="Keep your followers informed and engaged by sharing your latest projects and updates." />
        <Feature title="Streamline Your Workflow" text="Share your service offerings directly with clients, ensuring clarity and efficiency in your business interactions."/>
      </div>
   </div>
  )
}

export default Functions