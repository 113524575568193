import React from 'react';
import './hero.css';
import Herocards from './Herocards.jsx';

const Hero = () => {
  return (
    <div className='imfonin__hero section__padding' id='home'>
      <div className='imfonin__hero-content'>
        <div className='imfonin__hero-content-text'>
          <h1 className='gradient__text'>Bring the world alive with Imfonin</h1>
          <p>Imfonin offers a centralised hub to discover photographers with unparalleled skills and varied creative visions.</p>
        </div>
        <div className='imfonin__herocards-section'>
          <Herocards
            title="Are you a Photographer?"
            text="Grow Your Client Base"
            text1="Manage your bookings"
            text2="Showcase Your Portfolio"
            text3="Get Discovered and Inspired"
            text4="Receive tips and tokens"
            link={<button type='button' ><a href='#signup'>Request early access</a></button>}
          />
          <Herocards
            title="Looking to HIRE a Photographer?"
            text="Find Photographers"
            text1="Compare Their Prices"
            text2="See Their Work"
            text3="Book Quickly"
            link={<button type='button'><a href='#signup'>Request early access</a></button>}
          />
        </div>
      </div>
    </div>
  )
}

export default Hero