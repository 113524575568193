import React from 'react';
import './footer.css';
import { imfoninLogo } from '../../images';


const Footer = () =>{
  return (
    <div className='imfonin__footer section__padding'>
      <div className='imfonin__footer-heading'>
        <h1 className='gradient__text'>Imfonin: Elevate Your Photography Experience</h1>
      </div>
      <div className='imfonin__footer-btn'>
        <p><a href='#signup'>Get Started</a></p>
      </div>
      <div className='imfonin__footer-links'>
        <div className='imfonin__footer-links_logo'>
          <img src={imfoninLogo} alt="logo" />
        </div>
        <div className='imfonin__footer-links_div'>
          <h4>Links</h4>
         <p><a href='https://www.x.com/imfonin_'>X</a></p>
          <p><a href='https://www.linkedin.com/company/imfonin/'>LinkedIn</a></p>
          <p><a href='https://www.facebook.com/imfonin'>Facebook</a></p>
          <p>Contact</p>
        </div>
        <div className='imfonin__footer-links_div'>
          <h4>Company</h4>
          <p>About</p>
          <p>Privacy Policy</p>
          <p>Terms & Conditions</p>
        </div>
        <div className='imfonin__footer-links_div'>
          <h4>Get in touch</h4>
          <p>+233248914241</p>
          <p>imfonin@gmail.com</p>
          <p>45 Ntreh Avenue, Adenta, Accra</p>
        </div>
        </div>
        <div className='imfonin__footer-copyright'>
          <p>©2024 Imfonin. All rights reserved.</p>
        </div>
    </div>
  )
}

export default Footer